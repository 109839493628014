.triangle {
  width: 0;
  height: 0;
  border-top: 25px solid #700404;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  transform: rotate(45deg); /* Adjust the rotation angle as needed */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* This will apply the shadow on all four sides */
}

.main {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* This will apply the shadow on all four sides */
}

.shape {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04); /* Equivalent to shadow-xl in Tailwind */
  transform: rotate(45deg);
}

/* for admin page  */

.myButton {
  /* other styles */
}

.myButton:hover {
  background: linear-gradient(60deg, #45ebaf 0%, #2599e5 100%, #001991 100%);
}
